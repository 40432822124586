/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAVnKwNVkduYcuca_0DlnRTAJ7d2gtte_k",
  "appId": "1:820341865129:web:fc83f9b820208a066587b8",
  "authDomain": "schooldog-i-turner-ga.firebaseapp.com",
  "measurementId": "G-K2SWSNSKTQ",
  "messagingSenderId": "820341865129",
  "project": "schooldog-i-turner-ga",
  "projectId": "schooldog-i-turner-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-turner-ga.appspot.com"
}
